<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!------------------------------------STEPPER-------------------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-car"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-car"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <!-----------------------------------------TITLE-------------------------------------->
    <v-row
      justify="center"
      class="mt-6"
    >
      <v-col
        class="d-inline-flex"
        cols="12"
        sm="11"
        md="9"
      >
        <v-col
          cols="12"
          sm="7"
          md="7"
          class="d-flex align-center"
          :class="mobileDevice ? 'justify-center' : 'justify-start'"
        >
          <div class="title-text">
            Choose A Plan
          </div>
        </v-col>
        <v-col
          v-if="!mobileDevice"
          cols="5"
          class="d-flex justify-end align-center"
        >
          <v-btn
            class="ml-12 font-weight-bold"
            color="primary"
            to="/auto/info"
          >
            <v-icon
              left
            >
              mdi-pencil
            </v-icon>
            Edit Auto Details
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <div class="hidden-sm-and-down">
      <v-row
        class="d-flex justify-center"
      >
        <v-col
          cols="12"
          sm="11"
          md="9"
          class="d-flex justify-center"
        >
          <v-col cols="3">
            <v-card
              v-model="coverage"
              min-height="36rem"
              value="elite"
              class="pa-2 text-center"
              :class="eliteCoverage ? 'active' : 'plans'"
            >
              <v-card-title class="mb-3 primary--text d-flex justify-center">
                <strong>$1,854.88</strong>
              </v-card-title>
              <v-card-subtitle class="primary--text mb-3">
                For 6 Months
              </v-card-subtitle>
              <v-card-text>Bodily Injury 250,000/500,000</v-card-text>
              <v-card-text>Property Damage 100,000</v-card-text>
              <v-card-text>Medical Payments 5000</v-card-text>
              <v-card-text>Uninsured Motorist 250,000/500,000</v-card-text>
              <v-card-text>Comp/Collision $1000 Deductible</v-card-text>
              <v-btn
                outlined
                class="mb-6 font-weight-bold"
                :class="eliteCoverage ? 'selected' : 'select-btn'"
                @click="coverage = 'elite', clickedFieldGlobal('elite', 17, 'Auto Survey v1')"
              >
                {{ eliteCoverage ? 'SELECTED' : 'SELECT' }}
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              v-model="coverage"
              min-height="36rem"
              value="premium"
              class="pa-2 text-center"
              :class="premiumCoverage ? 'active' : 'plans'"
            >
              <v-card-title class="mb-3 primary--text d-flex justify-center">
                <strong>$1,637.59</strong>
              </v-card-title>
              <v-card-subtitle class="primary--text mb-3">
                For 6 Months
              </v-card-subtitle>
              <v-card-text>Bodily Injury 100,000/300,000</v-card-text>
              <v-card-text>Property Damage 50,000</v-card-text>
              <v-card-text>Medical Payments 2000</v-card-text>
              <v-card-text>Uninsured Motorist 100,000/300,000</v-card-text>
              <v-card-text>Comp/Collision $1000 Deductible</v-card-text>
              <v-btn
                outlined
                class="mb-6 font-weight-bold"
                :class="premiumCoverage ? 'selected' : 'select-btn'"
                @click="coverage = 'premium', clickedFieldGlobal('premium', 18, 'Auto Survey v1')"
              >
                {{ premiumCoverage ? 'SELECTED' : 'SELECT' }}
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              v-model="coverage"
              min-height="36rem"
              value="standard"
              class="pa-2 plans text-center"
              :class="standardCoverage ? 'active' : 'plans'"
            >
              <v-card-title class="mb-3 primary--text d-flex justify-center">
                <strong>$865.88</strong>
              </v-card-title>
              <v-card-subtitle class="primary--text mb-3">
                For 6 Months
              </v-card-subtitle>
              <v-card-text>Bodily Injury 50,000/100,000</v-card-text>
              <v-card-text>Property Damage 25,000</v-card-text>
              <v-card-text>Medical Payments 1000</v-card-text>
              <v-card-text>Uninsured Motorist 50,000/100,000</v-card-text>
              <v-card-text></v-card-text>
              <v-card-text></v-card-text>
              <v-btn
                outlined
                class="mb-6 font-weight-bold"
                :class="standardCoverage ? 'selected' : 'select-btn'"
                @click="coverage = 'standard', clickedFieldGlobal('standard', 19, 'Auto Survey v1')"
              >
                {{ standardCoverage ? 'SELECTED' : 'SELECT' }}
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              v-model="coverage"
              min-height="36rem"
              value="basic"
              class="pa-2 text-center"
              :class="basicCoverage ? 'active' : 'plans'"
            >
              <v-card-title class="mb-3 primary--text d-flex justify-center">
                <strong>$838.88</strong>
              </v-card-title>
              <v-card-subtitle class="primary--text mb-3">
                For 6 Months
              </v-card-subtitle>
              <v-card-text>Bodily Injury 25,000/50,000</v-card-text>
              <v-card-text>
                Property Damage 15,000
              </v-card-text>
              <v-card-text>
                Medical Payments $500
              </v-card-text>
              <v-card-text>
                Uninsured Motorist 25,000/50,000
              </v-card-text>
              <v-card-text></v-card-text>
              <v-card-text></v-card-text>
              <v-btn
                outlined
                class="mb-6 font-weight-bold"
                :class="basicCoverage ? 'selected' : 'select-btn'"
                @click="coverage = 'basic', clickedFieldGlobal('basic', 20, 'Auto Survey v1')"
              >
                {{ basicCoverage ? 'SELECTED' : 'SELECT' }}
              </v-btn>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="hidden-md-and-up">
      <v-row
        class="d-flex justify-center"
      >
        <v-col
          cols="12"
          sm="11"
          md="9"
          class="d-flex justify-center"
        >
          <v-slide-group>
            <v-col
              cols="2"
              sm="3"
            >
              <v-card
                v-model="coverage"
                min-height="36rem"
                value="elite"
                class="pa-2 text-center"
                :class="eliteCoverage ? 'active' : 'plans'"
              >
                <v-card-title class="mb-3 primary--text d-flex justify-center">
                  <strong>$1,854.88</strong>
                </v-card-title>
                <v-card-subtitle class="primary--text mb-3">
                  For 6 Months
                </v-card-subtitle>
                <v-card-text>Bodily Injury 250,000/500,000</v-card-text>
                <v-card-text>Property Damage 100,000</v-card-text>
                <v-card-text>Medical Payments 5000</v-card-text>
                <v-card-text>Uninsured Motorist 250,000/500,000</v-card-text>
                <v-card-text>Comp/Collision $1000 Deductible</v-card-text>
                <v-btn
                  outlined
                  class="mb-6 font-weight-bold"
                  :class="eliteCoverage ? 'selected' : 'select-btn'"
                  @click="coverage = 'elite', clickedFieldGlobal('elite', 21, 'Auto Survey v1')"
                >
                  {{ eliteCoverage ? 'SELECTED' : 'SELECT' }}
                </v-btn>
              </v-card>
            </v-col>
            <v-col
              cols="2"
              sm="3"
            >
              <v-card
                v-model="coverage"
                min-height="36rem"
                value="premium"
                class="pa-2 text-center"
                :class="premiumCoverage ? 'active' : 'plans'"
              >
                <v-card-title class="mb-3 primary--text d-flex justify-center">
                  <strong>$1,637.59</strong>
                </v-card-title>
                <v-card-subtitle class="primary--text mb-3">
                  For 6 Months
                </v-card-subtitle>
                <v-card-text>Bodily Injury 100,000/300,000</v-card-text>
                <v-card-text>Property Damage 50,000</v-card-text>
                <v-card-text>Medical Payments 2000</v-card-text>
                <v-card-text>Uninsured Motorist 100,000/300,000</v-card-text>
                <v-card-text>Comp/Collision $1000 Deductible</v-card-text>
                <v-btn
                  outlined
                  class="mb-6 font-weight-bold"
                  :class="premiumCoverage ? 'selected' : 'select-btn'"
                  @click="coverage = 'premium', clickedFieldGlobal('premium', 22, 'Auto Survey v1')"
                >
                  {{ premiumCoverage ? 'SELECTED' : 'SELECT' }}
                </v-btn>
              </v-card>
            </v-col>
            <v-col
              cols="2"
              sm="3"
            >
              <v-card
                v-model="coverage"
                min-height="36rem"
                value="standard"
                class="pa-2 plans text-center"
                :class="standardCoverage ? 'active' : 'plans'"
              >
                <v-card-title class="mb-3 primary--text d-flex justify-center">
                  <strong>$865.88</strong>
                </v-card-title>
                <v-card-subtitle class="primary--text mb-3">
                  For 6 Months
                </v-card-subtitle>
                <v-card-text>Bodily Injury 50,000/100,000</v-card-text>
                <v-card-text>Property Damage 25,000</v-card-text>
                <v-card-text>Medical Payments 1000</v-card-text>
                <v-card-text>Uninsured Motorist 50,000/100,000</v-card-text>
                <v-card-text></v-card-text>
                <v-card-text></v-card-text>
                <v-btn
                  outlined
                  class="mb-6 font-weight-bold"
                  :class="standardCoverage ? 'selected' : 'select-btn'"
                  @click="coverage = 'standard', clickedFieldGlobal('standard', 23, 'Auto Survey v1')"
                >
                  {{ standardCoverage ? 'SELECTED' : 'SELECT' }}
                </v-btn>
              </v-card>
            </v-col>
            <v-col
              cols="2"
              sm="3"
            >
              <v-card
                v-model="coverage"
                min-height="36rem"
                value="basic"
                class="pa-2 text-center"
                :class="basicCoverage ? 'active' : 'plans'"
              >
                <v-card-title class="mb-3 primary--text d-flex justify-center">
                  <strong>$838.88</strong>
                </v-card-title>
                <v-card-subtitle class="primary--text mb-3">
                  For 6 Months
                </v-card-subtitle>
                <v-card-text>Bodily Injury 25,000/50,000</v-card-text>
                <v-card-text>
                  Property Damage 15,000
                </v-card-text>
                <v-card-text>
                  Medical Payments $500
                </v-card-text>
                <v-card-text>
                  Uninsured Motorist 25,000/50,000
                </v-card-text>
                <v-card-text></v-card-text>
                <v-card-text></v-card-text>
                <v-btn
                  outlined
                  class="mb-6 font-weight-bold"
                  :class="basicCoverage ? 'selected' : 'select-btn'"
                  @click="coverage = 'basic', clickedFieldGlobal('basic', 24, 'Auto Survey v1')"
                >
                  {{ basicCoverage ? 'SELECTED' : 'SELECT' }}
                </v-btn>
              </v-card>
            </v-col>
          </v-slide-group>
        </v-col>
      </v-row>
    </div>
    <!------------------------------SECTION 2------------------------------->
    <v-row class="d-flex justify-center pb-16">
      <v-col
        cols="9"
        class="d-flex justify-center"
      >
        <v-btn
          :disabled="noCoverage"
          to="/auto/pay"
          class="font-weight-bold ma-0"
          color="primary"
          large
        >
          CHECKOUT
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        coverage: '',
        selected: ''
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      basicCoverage () {
        return this.coverage === 'basic'
      },
      standardCoverage () {
        return this.coverage === 'standard'
      },
      premiumCoverage () {
        return this.coverage === 'premium'
      },
      eliteCoverage () {
        return this.coverage === 'elite'
      },
      noCoverage () {
        return this.coverage === ''
      },
    },
    methods: {
      selectCoverage () {
        this.isActive = !this.isActive
      }
    }
  }
</script>
<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00A1B7;
}
.pet-details{
   border: 1px solid #00A1B7;
   background-color: none !important;
 }
 .edit-details{
   text-decoration: none;
   font-weight: 700;
 }
 .edit-pet {
   border: 1px solid #00A1B7;
 }
 .section1 {
   background-color: #D8FDD7;
   height: 380px;
 }
 .section2 {
   background-color: #DEECF1;
 }
 .title-heading {
   font-weight: 400;
   font-size: 36px;
   color:#00A1B7;
 }
 .title {
   font-size: 36px;
 }
 .gray3 {
   color: #D8D8D8;
 }
 .select-btn{
  background: #FFFFFF;
  border: 2px solid #00A1B7;
  color: #00A1B7;
  box-sizing: border-box;
  border-radius: 4px;
 }
 .active{
   border: 2px solid #00A1B7;
   background-color: #DEECF1;
 }
 .selected {
   background-color: #00A1B7;
   color: white;
 }
 .add-pet {
   font-size: 24px;
   color:#00A1B7;
 }

 .enhance-text {
   color: #757575;
 }

.checkbox {
    color: #757575;
    font-size: 18px;
}
</style>
